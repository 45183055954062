
/*
*****************************
*****************************
*****************************
All selector filter functions
*****************************
*/

// Platforms selector filter function. (Help me choose) 
function legacyProductsInitialize($) {
	jQuery(document).ready( function($){
		//highlight in yellow
		$('#filterInput').bind('keyup change', function(ev) {
			var searchQuery = $(this).val();
			$('body').removeHighlight();
			if ( searchQuery ){
				$('#productSelector .toggle .togglet').highlight( searchQuery );
			}
		});
		//text filter
		$('#filterInput').typing({
			stop: function (event, $elem) {
				legacySelector();
			},
			delay: 50
		});
		legacySelector();
	});

	function legacySelector() {
		//text typed in the filter
		var queryValue = $('#filterInput').val();
		// Create the RegExp if necessary for the keywords
		if (queryValue) {
			var queryRegExp = new RegExp(queryValue, "i");
		}
		// By default, we show all items
		$('#productSelector .toggle .togglet').each(function() {
			var showProduct = true;
			// Keywords?
			if (queryValue) {
				if ($(this).text().search(queryRegExp) == -1) {
					showProduct = false;
				}
			}
			// Finally, show or hide
			if (showProduct) {
				$(this).show(50);
			}
			else {
				$(this).hide(50);
			}
		});
	}
}

// Platforms selector filter function. (Help me choose) 
function platformsInitialize($) {
	jQuery(document).ready( function($){
		//highlight in yellow
		$('#filterInput').bind('keyup change', function(ev) {
			var searchQuery = $(this).val();
			$('body').removeHighlight();
			if ( searchQuery ){
				$('#productSelector tbody').highlight( searchQuery );
			}
		});
		//text filter
		$('#filterInput').typing({
			stop: function (event, $elem) {
				platformsSelector();
			},
			delay: 50
		});
		platformsSelector();
	});

	function platformsSelector() {
		//text typed in the filter
		var queryValue = $('#filterInput').val();
		//total of products displayed
		var totalCount = 0;
		// Create the RegExp if necessary for the keywords
		if (queryValue) {
			var queryRegExp = new RegExp(queryValue, "i");
		}
		// By default, we show all items
		$('#productSelector tbody tr').each(function() {
			var showProduct = true;
			// Keywords?
			if (queryValue) {
				if ($(this).text().search(queryRegExp) == -1) {
					showProduct = false;
				}
			}
			// Finally, show or hide
			if (showProduct) {
				$(this).show(50);
				totalCount++;
			}
			else {
				$(this).hide(50);
			}
		});
		if (totalCount < 1) {
				$("#noResults").show(50);
			}
			else {
				$("#noResults").hide(50);
			}
	}
}

// All products selector filter function
// Linked to "More products" links in main menu with hash #
// Linked also with onhashchange="allProductsSelector()" in the <body> tag
function allProductsInitialize ($) {
	jQuery(document).ready( function($){
		//highlight in yellow
		$('#icons-filter').bind('keyup change', function(ev) {
			var searchTerm = $(this).val();
			$('body').removeHighlight();
			if ( searchTerm ){
				$('#ProductsDisplay').highlight( searchTerm );
			}
		});

		$("#productsTable").tablesorter( {
			// by default: sort by 3rd col [0,1,2], DESC (ASC=0, DESC=1)
			sortList: [[1,0]]
		} );

		//solution dropdown menu
		$('select#solution-filter').change(allProductsSelector);
		//text filter
		$('#icons-filter').typing({
			stop: function (event, $elem) {
				allProductsSelector();
			},
			delay: 300
		});
		allProductsSelector();
	});

	function allProductsSelector() {
		//text typed in the filter
		var filterValue = $('#icons-filter').val();
		//selected solution in the dropdown menu
		var solutionLink = $('#solution-filter option:selected');
		//attribute of the selected solution
		var solutionValue = $( solutionLink ).data("solution");
		//product code (hashtag) in the URL = sm || sco
		var productCodeValue = window.location.hash;
		//total of products displayed
		var count = 0;

		// Extract the requested productCode (if any)
		if (productCodeValue) {
			// Remove the '#'
			productCodeValue = productCodeValue.substring(1);
			// Clear the '#' so the solution menu works
			window.location.hash = "";
		}

		// Create the RegExp if necessary for the keywords
		if (filterValue) {
			var keywordsRegExp = new RegExp(filterValue, "i");
		}

		// By default, we show all items
		$("#productsTable tbody tr").each(function() {
			var showItem = true;

			// Solution in the hash filters the table and selects the value of the HTML select
			if (productCodeValue) {
				if ($(this).data("solution") != productCodeValue) {
						var filter = "#solution-filter option[data-solution=" + productCodeValue + "]";
						$(filter).prop('selected', true);
						//$(`#solution-filter option[data-solution=${productCodeValue}]`).prop('selected', true);
						showItem = false;
					}
			}

			// Keywords?
			if (filterValue) {
				if ($(this).text().search(keywordsRegExp) == -1) {
					showItem = false;
				}
			}

			// solution?
			if (solutionValue) {
				if (solutionValue != 'all'){
					if ($(this).data("solution") != solutionValue) {
					showItem = false;
					}
				}
			}

			// Finally, show or hide
			if (showItem) {
				$(this).show(300);
				count++;
			}
			else {
				$(this).hide(300);
			}

		});

		$("#productsTotal").html(count);
	}

}

// Patches selector filter function.
function patchesHomeInitialize ($) {
	jQuery(document).ready( function($){
		//highlight in yellow
		$('#textFilter').bind('keyup change', function(ev) {
			var searchTerm = $(this).val();
			$('body').removeHighlight();
			if ( searchTerm ){
				$('#patchesTable, #bundlesTable').highlight( searchTerm );
			}
		});
	
		//product dropdown menu
		$('select#productFilter').change(patchesHomeSelector);
		//text filter
		$('#textFilter').typing({
			stop: function (event, $elem) {
				patchesHomeSelector();
			},
			delay: 300
		});
		patchesHomeSelector();
	});
	
	function patchesHomeSelector() {
		//text typed in the filter
		var filterValue = $('#textFilter').val();
		//selected product in the dropdown menu
		var productLink = $('#productFilter option:selected');
		//attribute of the selected product
		var productValue = $( productLink ).data("product");
		//total of products displayed
		var countP = 0;
		var countB = 0;
	
		// Create the RegExp if necessary for the keywords
		if (filterValue) {
			var keywordsRegExp = new RegExp(filterValue, "i");
		}
	
		// By default, we show all items
		$("#patchesTable tbody tr").each(function() {
			var showItem = true;
			// Keywords?
			if (filterValue) {
				if ($(this).text().search(keywordsRegExp) == -1) {
					showItem = false;
				}
			}
			// product?
			if (productValue) {
				if (productValue != 'all'){
					if ($(this).data("product") != productValue) {
					showItem = false;
					}
				}
			}
			// Finally, show or hide
			if (showItem) {
				$(this).show(300);
				countP++;
			}
			else {
				$(this).hide(300);
			}
			// Count number of patches
			// And display this number in a badge
			if (countP > 0) {
				$("#patchesTable thead").show();
				$("#patchesTotal").css("background-color","#39f");
			}
			else {
				$("#patchesTable thead").hide();
				$("#patchesTotal").css("background-color","grey");
			}
		});
		$("#bundlesTable tbody tr").each(function() {
			var showItem = true;
			// Keywords?
			if (filterValue) {
				if ($(this).text().search(keywordsRegExp) == -1) {
					showItem = false;
				}
			}
			// product?
			if (productValue) {
				if (productValue != 'all'){
					if ($(this).data("product") != productValue) {
					showItem = false;
					}
				}
			}
			// Finally, show or hide
			if (showItem) {
				$(this).show(300);
				countB++;
			}
			else {
				$(this).hide(300);
			}
			// Count number of bundles
			// And display this number in a badge
			if (countB > 0) {
				$("#bundlesTable thead").show();
				$("#bundlesTotal").css("background-color","#39f");
			}
			else {
				$("#bundlesTable thead").hide();
				$("#bundlesTotal").css("background-color","grey");
			}
		});
		$("#bundlesTotal").html(countB);
		$("#patchesTotal").html(countP);
	}
}

// Product Lifecycle selector filter function.
function productLifecycleInitialize ($) {
		// this function initialize the table sorter plugin
		// by default: sort by 3rd col [0,1,2], DESC (ASC=0, DESC=1)
		// add parser through the tablesorter addParser method
		$.tablesorter.addParser({
			// set a unique id
			id: 'dates',
			is: function(s) {
				// return false so this parser is not auto detected
				return false;
			},
			format: function(s) {
				// format your data for normalization
				var year = s.replace(/ [a-z]+ /gi,"");
				var month = s.replace(/jan/gi,"01").replace(/feb/gi,"02").replace(/mar/gi,"03").replace(/apr/gi,"04").replace(/may/gi,"05").replace(/jun/gi,"06")
							 .replace(/jul/gi,"07").replace(/aug/gi,"08").replace(/sep/gi,"09").replace(/oct/gi,"10").replace(/nov/gi,"11").replace(/dec/gi,"12");
				return year + month;
			},
			// set type, either numeric or text
			type: 'text' 
		}); 

		jQuery(document).ready( function($){
			//highlight in yellow
			$('#icons-filter').bind('keyup change', function(ev) {
				var searchTerm = $(this).val();
				$('body').removeHighlight();
				if ( searchTerm ){
					$('#ProductsDisplay').highlight( searchTerm );
				}
			});

			$("#productsTable").tablesorter( {
				// by default: sort by 3rd col [0,1,2], DESC (ASC=0, DESC=1)
				sortList: [[2,1]],
				// assign the 4rd (3) column (we start counting zero) and disable the sorter
				headers: {2: {sorter:'dates'}, 3: {sorter: false}}
			} );

			//checkbox config template
			$('input#latest-filter').click(productLifecycleSelector);
			//solution dropdown menu
			$('select#solution-filter').change(productLifecycleSelector);
			//text filter
			$('#icons-filter').typing({
				stop: function (event, $elem) {
					productLifecycleSelector();
				},
				delay: 300
			});
			productLifecycleSelector();
		});

		function productLifecycleSelector() {
			//checked property of the checkbox : true or false
			var checkBoxStatus = $('input#latest-filter').prop('checked');
			//text typed in the filter
			var filterValue = $('#icons-filter').val();
			//selected solution in the dropdown menu
			var solutionLink = $('#solution-filter option:selected');
			//attribute of the selected solution
			var solutionValue = $( solutionLink ).data("solution");
			//total of products displayed
			var count = 0;

			// Create the RegExp if necessary for the keywords
			if (filterValue) {
				var keywordsRegExp = new RegExp(filterValue, "i");
			}

			// By default, we show all items
			$("#productsTable tbody tr").each(function() {
				var showItem = true;

				// Keywords?
				if (filterValue) {
					if ($(this).text().search(keywordsRegExp) == -1) {
						showItem = false;
					}
				}

				// solution?
				if (solutionValue) {
					if (solutionValue != 'all'){
						if ($(this).data("solution") != solutionValue) {
						showItem = false;
						}
					}
				}

				//latest?
				if (checkBoxStatus){
					if ($(this).data("latest") != true) {
					showItem = false;
					}
				}

				// Finally, show or hide
				if (showItem) {
					$(this).show(300);
					count++;
				}
				else {
					$(this).hide(300);
				}

			});

			$("#productsTotal").html(count);
		}

}

// Documentation selector filter function.
function userDocInitialize ($) {

		jQuery(document).ready( function($){
			//highlight in yellow
			$('#icons-filter').bind('keyup change', function(ev) {
				var searchTerm = $(this).val();
				$('body').removeHighlight();
				if ( searchTerm ){
					$('#ProductsDisplay').highlight( searchTerm );
				}
			});

			$("#productsTable").tablesorter( {
				// by default: sort by 1st col ASC [0,0], DESC (ASC=0, DESC=1)
				sortList: [[0,0]],
			} );

			//solution dropdown menu
			$('select#solution-filter').change(userDocSelector);
			//text filter
			$('#icons-filter').typing({
				stop: function (event, $elem) {
					userDocSelector();
				},
				delay: 300
			});
			userDocSelector();
		});

		function userDocSelector() {	
			//text typed in the filter
			var filterValue = $('#icons-filter').val();
			//selected solution in the dropdown menu
			var solutionLink = $('#solution-filter option:selected');
			//attribute of the selected solution
			var solutionValue = $( solutionLink ).data("solution");
			//total of products displayed
			var count = 0;

			// Create the RegExp if necessary for the keywords
			if (filterValue) {
				var keywordsRegExp = new RegExp(filterValue, "i");
			}

			// By default, we show all items
			$("#productsTable tbody tr").each(function() {
				var showItem = true;

				// Keywords?
				if (filterValue) {
					if ($(this).text().search(keywordsRegExp) == -1) {
						showItem = false;
					}
				}	

				// solution?
				if (solutionValue) {
					if (solutionValue != 'all'){
						if ($(this).data("solution") != solutionValue) {
						showItem = false;
						}
					}
				}

				// Finally, show or hide
				if (showItem) {
					$(this).show(300);
					count++;
				}
				else {
					$(this).hide(300);
				}

			});

			$("#productsTotal").html(count);
		}

}

// KB home selector filter function.
function kbHomeInitialize ($) {
	jQuery(document).ready( function($){
		//highlight in yellow
		$('#icons-filter').bind('keyup change', function(ev) {
			var searchTerm = $(this).val();
			$('body').removeHighlight();
			if ( searchTerm ){
				$('#KB-Display').highlight( searchTerm );
			}
		});
		//checkbox config template
		$('input#templates-filter').click(kbHomeSelector);
		//text filter
		$('#icons-filter').typing({
			stop: function (event, $elem) {
				kbHomeSelector();
			},
			delay: 300
		});
	});
	
	function kbHomeSelector() {
		//text typed in the filter
		var _filterValue = $('#icons-filter').val();
		//checked property of the checkbox : true or false
		var _checkBoxStatus = $('input#templates-filter').prop('checked');
		//keyword to search when checkbox is checked
		var _checkBoxKeyword = "Configuration Template";
		//number of items
		var _count = 0;
		
		if (_filterValue){// if text filter is not empty
			if (_checkBoxStatus){// and checkbox is checked = true
				$('#KB-Display tr').css('display', 'none');
				$('#KB-Display tr:contains('+ _checkBoxKeyword +')').each(function(){
					if ($(this).text().search(new RegExp(_filterValue, "i")) >= 0) {
						$(this).show();
						_count++;
					}
				});
			} else {// and checkbox is not checked = false
				$('#KB-Display tr').css('display', 'none');
				$('#KB-Display tr').each(function(){
					if ($(this).text().search(new RegExp(_filterValue, "i")) >=0) {
						$(this).show();
						_count++;
					}
				});
			}
		} else {// if text filter is empty
			if (_checkBoxStatus){// and checkbox is checked = true
				$('#KB-Display tr').css('display', 'none');
				$('tbody#KB-Display tr td:contains('+ _checkBoxKeyword +')').parent().show(500);
				_count = $('tbody#KB-Display tr td:contains('+ _checkBoxKeyword +')').parent().length;
			} else {// and checkbox is not checked = false
				$('#KB-Display tr').show();
				_count = 0;
			}
		}
		if( _count > 1 ) {
			$('#icons-filter-result').html('<strong>' + _count + '</strong> Articles Found');
		} else if ( _count == 1 ) {
			$('#icons-filter-result').html('<strong>1</strong> Article Found');
		} else {
			$('#icons-filter-result').html('');
		}	
	}
}

